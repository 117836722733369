import { PAYMENT_SUCCESS_QUERY, PAYMENT_FAIL_QUERY } from '../../constants/payment/paymentConstants';

const PAYMENT_ISSUE_COUNTRIES = process.env.VUE_APP_PAYMENT_ISSUE_COUNTRIES;

const hasPaymentIssue = ({ countryCode, locale }) => {
  if (locale !== 'ru') return false;
  // const { from_country } = await userService.getUserVisitInfo();
  return PAYMENT_ISSUE_COUNTRIES.includes(countryCode);
};

const needPaymentWarning = ({ countryCode, locale }) => {
  // const wasPaymentWarningShown = localStorage.getItem('isPaymentWarningShown');
  // if (wasPaymentWarningShown) return false;
  const hasIssue = hasPaymentIssue({ countryCode, locale });
  // if (hasIssue) localStorage.setItem('isPaymentWarningShown', true);
  return hasIssue;
};

const getPaymentSuccessUrl = () => `${window.location.href.split('?')[0]}?${PAYMENT_SUCCESS_QUERY}`;

const getPaymentFailUrl = () => `${window.location.href.split('?')[0]}?${PAYMENT_FAIL_QUERY}`;

const isPaymentSucceeded = (queryParams) => Object.keys(queryParams).includes(PAYMENT_SUCCESS_QUERY);

const isPaymentFailed = (queryParams) => Object.keys(queryParams).includes(PAYMENT_FAIL_QUERY);

export default {
  hasPaymentIssue,
  needPaymentWarning,
  isPaymentSucceeded,
  isPaymentFailed,
  getPaymentSuccessUrl,
  getPaymentFailUrl,
};
